import { createPortal } from "react-dom";

const portalContainer = document.getElementById('portal-root') || document.body;

const IconTooltip = ({ tooltipData, label }: { tooltipData: { top: number; left: number } | null, label: string }) => {
    if (!tooltipData) return null;

    return createPortal(
        <div
            style={{
                position: 'absolute',
                left: `${tooltipData.left}px`,
                top: `${tooltipData.top}px`,
                zIndex: 1000,
            }}
            className="bg-white text-black41 text-xs rounded px-4 py-1 transition-opacity drop-shadow-lg"
        >
            {label}
            <span className="absolute top-1/2 right-full -translate-y-1/2 w-0 h-0 border-t-[6px] border-b-[6px] border-r-[6px] border-transparent border-r-white"></span>
        </div>,
        portalContainer
    );
};

export default IconTooltip;

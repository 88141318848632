import { useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import { Column, useTable, useSortBy, Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/pro-light-svg-icons";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import MainFooter from "../../UI/MainFooter";
import Moment from "react-moment";
import NoResultMessage from "../../UI/NoResultMessage";
import TrainingCandidate from "../../../types/TrainingCandidate";
import TrainingCandidatesApi from "../../../store/api/TrainingCandidatesApi";
import saveAs from "file-saver";
import { getDate } from "../../../utils/getDate";
import { getValueFromTupleList, getValueFromObjectList } from "../../../utils/getValue";
import Pagination from "../../UI/Pagination";
import { ButtonTypes, SnackBarType, StatusBadgeTypes } from "../../../utils/Constants";
import StatusBadge from "../../UI/StatusBadge";
import SearchSection from "../../UI/SearchSection";
import FilterSection, { FilterState } from "./TrainingCandidatesFilterSection";
import Button from "../../UI/Button";
import { useNavigate } from "react-router-dom";
import LastConfirmWarningModal from "../../UI/LastConfirmWarningModal";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { createPortal } from "react-dom";
import { renderSortableHeader, SortConfig } from "../../../utils/renderSortableHeader";
import { toggleSelectAll } from "../../../utils/toggleSelectAll";
import { toggleRowSelection } from "../../../utils/toggleRowSelection";

export type SnackbarType = {
  message: string;
  type: number;
  show: boolean;
};

const TrainingCandidates: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [responseData, setResponseData] = useState<TrainingCandidate[]>([]);
  const [referralList, setReferralList] = useState<[string, string][]>([]);
  const [organizationList, setOrganizationList] = useState<[string, string][]>([]);
  const [filterState, setFilterState] = useState<FilterState>({
    referral: "AL",
    status: "AL",
    dateFrom: null,
    dateTo: null,
  });
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    message: "",
    type: 0,
    show: false,
  });
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataCount, setDataCount] = useState<number>(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [trainingCandidateToDelete, setTrainingCandidateToDelete] = useState<number | null>(null);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const [dropdownMenuPosition, setDropdownMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const { getTrainingCandidates, deleteTrainingCandidate, exportTrainingCandidates } = TrainingCandidatesApi();

  const getTrainingCandidatesList = () => {
    setShowLoading(true);
    getTrainingCandidates(`${"training-candidate/"}${constructQueryParams()}`, async (response: Response) => {
      const result = await response.json();
      if (!response.ok) {
        return;
      }

      const processedTrainees = result.trainees.map((trainee: any) => ({
        ...trainee,
        referral: getValueFromTupleList(trainee.referral, [...result.referrals, ...result.socials]),
        clinic: getValueFromObjectList(trainee.clinic, result.organizations),
      }));

      setResponseData(processedTrainees);
      setReferralList([...result.referrals, ...result.socials]);
      setOrganizationList(result.organizations);
      setDataCount(result.total_count);
      setShowLoading(false);
    });
  };

  const constructQueryParams = (includePagination: boolean = true) => {
    let queryParams: string[] = [];
    if (includePagination) queryParams.push(`page_number=${pageIndex + 1}&page_size=${pageSize}`);
    if (searchInput) {
      queryParams.push(`search_trainee=${searchInput}`);
    }
    if (filterState.referral !== "AL") {
      queryParams.push(`trainee_referral=${filterState.referral}`);
    }
    if (filterState.status !== "AL") {
      queryParams.push(`trainee_status=${filterState.status}`);
    }
    if (filterState.dateFrom !== null) {
      queryParams.push(`trainee_from=${getDate(filterState.dateFrom.toString(), false)}`);
    }
    if (filterState.dateTo !== null) {
      queryParams.push(`trainee_to=${getDate(filterState.dateTo.toString(), false)}`);
    }
    if (sortConfig) {
      queryParams.push(`sort_by=${sortConfig.column}&sort_type=${sortConfig.sortType}`);
    }
    return `?${queryParams.join("&")}`;
  };

  useEffect(() => {
    setPageIndex(0);
  }, [searchInput, filterState]);

  useEffect(() => {
    getTrainingCandidatesList();
  }, [pageIndex, pageSize, searchInput, filterState, sortConfig]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuButtonRef.current && !menuButtonRef.current.contains(event.target as Node)) {
        setActiveRowId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns: Column<TrainingCandidate>[] = useMemo(
    () => [
      {
        Header: (props) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              onChange={() => toggleSelectAll(responseData, selectedRowIds, setSelectedRowIds, (trainee) => trainee.id)}
              checked={responseData.length > 0 && responseData.every((row) => selectedRowIds.includes(row.id))}
              className="table__checkbox mr-4"
            />
            {renderSortableHeader(props.column, t("full_name"), sortConfig, setSortConfig)}
          </div>
        ),
        accessor: (row) => `${row.first_name} ${row.last_name}`,
        id: "full_name",
        sortType: (rowA, rowB) => {
          const fullNameA = `${rowA.original.first_name} ${rowA.original.last_name}`.toLowerCase();
          const fullNameB = `${rowB.original.first_name} ${rowB.original.last_name}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        },
        Cell: ({ row }: { row: Row<TrainingCandidate> }) => (
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={selectedRowIds.includes(row.original.id)}
              onChange={() => toggleRowSelection(row.original.id, setSelectedRowIds)}
              className="table__checkbox mr-4"
            />
            <span>{`${row.original.first_name} ${row.original.last_name}`}</span>
          </div>
        ),
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("email"), sortConfig, setSortConfig),
        accessor: "email",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("phone"), sortConfig, setSortConfig),
        accessor: "phone",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("clinic"), sortConfig, setSortConfig),
        accessor: "clinic",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("referral_source"), sortConfig, setSortConfig),
        accessor: "referral",
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("contact_date"), sortConfig, setSortConfig),
        accessor: "contact_date",
        Cell: ({ value }) => <Moment format="DD/MM/YYYY">{value}</Moment>,
      },
      {
        Header: (props) => renderSortableHeader(props.column, t("status"), sortConfig, setSortConfig),
        accessor: "status",
        Cell: ({ value }: { value: string }) => {
          let statusType: StatusBadgeTypes | undefined;
          switch (value) {
            case "RS":
              statusType = StatusBadgeTypes.RESPONDED;
              break;
            case "PD":
              statusType = StatusBadgeTypes.PENDING;
              break;
            default:
              statusType = undefined;
          }
          return statusType ? <StatusBadge type={statusType} /> : <span>{t("unknown_status")}</span>;
        },
      },
    ],
    [t, selectedRowIds, responseData, sortConfig]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<TrainingCandidate>(
    {
      columns: columns,
      data: responseData,
    },
    useSortBy
  );

  const deleteHandler = (_id: number) => {
    deleteTrainingCandidate((response: Response) => {
      if (response.ok) {
        setSnackbar({
          message: t("record_deleted_successfully"),
          type: SnackBarType.EXPORT,
          show: true,
        });
        const newData = responseData.filter((candidate) => candidate.id !== _id);
        setResponseData(newData);
      }
    }, _id);
    setShowDeleteModal(false);
    setTrainingCandidateToDelete(null);
  };

  const exportHandler = () => {
    setShowLoadingSpinner(true);
    exportTrainingCandidates(
      constructQueryParams(false) +
      (selectedRowIds.length > 0
        ? `&selected_items=${selectedRowIds.map((selectedItem) => selectedItem).join(",")}`
        : ""),
      async (response: Response) => {
        if (response.ok) {
          const blob = await response.blob();
          saveAs(blob, `"training_candidates".csv`);
          setSnackbar({
            message: t("export_completely_message"),
            type: SnackBarType.EXPORT,
            show: true,
          });
          setShowLoadingSpinner(false);
        } else {
          setShowLoadingSpinner(false);
        }
      }
    );
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": { backgroundColor: "#03A678" },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            message: "",
            type: 0,
            show: false,
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>

      {showLoadingSpinner && (
        <LoadingSpinner titleKey={t("export_to_csv")} onBackdrop={() => setShowLoadingSpinner(false)} />
      )}

      <main className="alignment mt-4">
        <header className="flex flex-row justify-between items-center flex-wrap gap-x-32 gap-y-2">
          <h3 className="text-lg font-semibold text-gray-27 my-auto">{t("training_candidates")}</h3>
          {responseData.length > 0 && !showLoading && (
            <Button onClick={exportHandler} type={ButtonTypes.PRIMARY} className="max-w-[155px]">
              {t("export_to_csv")}
            </Button>
          )}
        </header>

        <div className="bg-white rounded shadow px-6 mt-4">
          <SearchSection
            onSearch={(_searchInput) => {
              setSearchInput(_searchInput);
            }}
            onSliderClicked={() => {
              setShowFilterSection((preState) => !preState);
            }}
            isFilterMode={showFilterSection}
          />
          {showFilterSection && (
            <FilterSection
              referralList={referralList}
              onApply={(appliedFilterState: FilterState) => {
                setFilterState(appliedFilterState);
              }}
              onReset={() => {
                setFilterState({
                  referral: "AL",
                  status: "AL",
                  dateFrom: null,
                  dateTo: null,
                });
              }}
              initialFilterState={filterState}
            />
          )}
        </div>

        <div className="relative bg-white rounded shadow px-4 mt-[6px] pb-6">
          <div className="mb-10 overflow-x-auto z-10 min-h-[400px]">
            {showLoading ? (
              <div className="flex w-full h-[500px] items-center justify-center">
                <CircularProgress size={64} />
              </div>
            ) : responseData.length > 0 ? (
              <table {...getTableProps()} className="w-full text-black41 text-sm">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps()}
                          className={`table__cell text-left sticky ${index === 0 && `z-10 left-0`}`}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="border-t border-gray-df">
                        {row.cells.map((cell, index) => (
                          <td
                            {...cell.getCellProps()}
                            className={`py-4 whitespace-nowrap table__cell ${index === 0 && `sticky left-0`}`}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                        <td className="table__cell">
                          <div
                            ref={menuButtonRef}
                            className="cursor-pointer py-1 px-2.5 rounded hover:bg-gray-f2"
                            // onClick={(event: React.MouseEvent) => {
                            onMouseDown={(event: React.MouseEvent) => {
                              event.stopPropagation();
                              if (activeRowId === row.original.id) {
                                setActiveRowId(null);
                              } else {
                                const buttonElement = event.currentTarget as HTMLDivElement;
                                const rect = buttonElement.getBoundingClientRect();
                                setDropdownMenuPosition({
                                  top: rect.bottom + window.scrollY + 4,
                                  left: rect.left + window.scrollX - 104,
                                });
                                setActiveRowId(row.original.id);
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faEllipsisV} className="text-blue-primary" />
                          </div>

                          {activeRowId === row.original.id &&
                            dropdownMenuPosition &&
                            createPortal(
                              <div
                                className="absolute w-32 bg-white rounded-md shadow-lg z-50"
                                style={{ top: dropdownMenuPosition.top, left: dropdownMenuPosition.left }}
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <ul className="text-sm text-gray-50">
                                  <li
                                    className="px-4 py-2.5 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => {
                                      navigate(`/training-candidate/${row.original.id}`, {
                                        state: {
                                          referralList,
                                          organizationList,
                                        },
                                      });
                                      setActiveRowId(null);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEye} className="mr-[5px]" />
                                    {t("preview")}
                                  </li>
                                  <li
                                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => {
                                      setTrainingCandidateToDelete(row.original.id);
                                      setShowDeleteModal(true);
                                      setActiveRowId(null);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} className="mr-[5px]" />
                                    {t("delete")}
                                  </li>
                                </ul>
                              </div>,
                              document.body
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <NoResultMessage noResultOrNoData={true} />
            )}
          </div>

          {!showLoading && responseData.length > 0 && (
            <Pagination
              dropdownKeys={["5", "10", "20"]}
              pageIndex={pageIndex}
              pageSize={pageSize}
              dataCount={dataCount}
              onPageChange={setPageIndex}
              onPageSizeChange={(newSize) => {
                setPageSize(newSize);
                setPageIndex(0);
              }}
            />
          )}
        </div>

        {showDeleteModal && (
          <LastConfirmWarningModal
            title="delete"
            message="delete_record_message"
            confirmButtonLabel="yes_delete"
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={() => {
              if (trainingCandidateToDelete !== null) deleteHandler(trainingCandidateToDelete);
            }}
            onBackdrop={() => setShowDeleteModal(false)}
          />
        )}
        <MainFooter />
      </main>
    </MainContainer>
  );
};

export default TrainingCandidates;
